
import { computed, defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import IconSpeed from '@/assets/images/home/icon_speed.png';
import IconStyle from '@/assets/images/icon_style_title.png';
import IconLeftArrow from '@/assets/images/arrow_left.png';
import IconRightArrow from '@/assets/images/arrow_right.png';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper';
import { ElMessage } from 'element-plus';
import {
  confirmImage,
  downloadImageBySpu,
  getDyProductListInfo,
  loadMoreDyLive,
} from '@/modules/doupinku-manage/api';
import { IDyProductFindBySpuRes, IDyProductImageSelectionReq } from '@/modules/doupinku-manage/api/type.d';
import { datefuns } from 'cx-utils';
import { GOODS_STATE_LIST } from '@/modules/doupinku-manage/constant';
import { IGetDesignTaskOptionsRes } from '@/modules/task-manage/design-task/api/types';
import { getDesignTaskOptions } from '@/modules/task-manage/design-task/api';
import SelectImgDialog from '../components/select-img-dialog/index.vue';
import usePermissionConfig from '../use-permission-config';
import { CHANNEL_ENUM } from '@/constant/global';
import { trackingClick } from '@/utils/tracking';

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    SelectImgDialog,
  },
  emits: [
    'select-img',
    'goods',
  ],
  setup() {
    const $router = useRouter();
    const route = useRoute();
    const permissionConfig = usePermissionConfig();
    // 轮播灯箱图选中项index
    const currentIndex = ref(0);
    // 轮播灯箱组件
    const swiperEl = ref<any>(null);
    // 详细信息
    const info = ref<IDyProductFindBySpuRes|null>(null);

    // 获取页面详细信息
    const getInfo = async () => {
      const { query: { spu = '' } } = route;
      currentIndex.value = 0;
      info.value = {} as IDyProductFindBySpuRes;
      const { data } = await getDyProductListInfo({
        spu: spu as string,
      });
      if (data) {
        info.value = {
          ...data,
          productParametersObj: JSON.parse(data.productParameters),
        };
      } else {
        ElMessage.error('商品不存在');
      }
    };
    const handleItem = (index: number) => {
      if (index !== currentIndex.value) {
        currentIndex.value = index;
      }
    };
    const handleNext = () => {
      if (!info.value) return;
      if (currentIndex.value < info.value.pictureList.length - 1) {
        currentIndex.value += 1;
        swiperEl.value.slideTo(currentIndex.value);
      }
    };
    const handlePrev = () => {
      if (currentIndex.value > 0) {
        currentIndex.value -= 1;
        swiperEl.value.slideTo(currentIndex.value);
      }
    };

    const designTaskOptions = ref<IGetDesignTaskOptionsRes[]>([]);
    const getDesignOptions = async () => {
      try {
        const { data } = await getDesignTaskOptions();
        designTaskOptions.value = data;
      } catch (error) {
        console.log(error);
      }
    };
    getDesignOptions();

    // 选图｜快速选图
    const selectImgConfig = ref<{data:IDyProductImageSelectionReq;visible:boolean;}>(
      {
        data: { id: '', spu: '', channelId: '', categoryClass: '' },
        visible: false,
      },
    );
    const handleSelectImgItem = async (curInfo: IDyProductFindBySpuRes|null, type: 'quick'|'normal' = 'normal') => {
      const id = localStorage.getItem('quick_design_id');
      let eventDetails: { [key: string]: string; } = {
        eventName: '选图',
        goodId: curInfo?.spu || '',
      };
      if (type === 'quick') {
        eventDetails = {
          ...eventDetails,
          eventName: '快速选图',
          taskId: id || '',
        };
      }
      trackingClick(eventDetails);
      if (curInfo) {
        const { spu = '', secondCname = '', firstCname = '', channelId = '' } = curInfo;
        const param: IDyProductImageSelectionReq = {
          id: id || '',
          spu,
          categoryClass: secondCname || firstCname,
          channelId,
        };
        if (type === 'quick' && id) {
          await confirmImage(param);
          const row = designTaskOptions.value.find(v => v.id === id);
          if (row) {
            ElMessage.success(`已添加到想法${row.designTaskName}`);
          }
          return;
        }
        selectImgConfig.value = { data: param, visible: true };
      }
    };

    // 下载图片
    const handleDownload = async (curInfo: IDyProductFindBySpuRes|null) => {
      if (curInfo) {
        const { spu = '', spuName = '' } = curInfo;
        try {
          await downloadImageBySpu({
            spu,
            filename: `${spuName}-${datefuns.formatTime(new Date(), 'YYYY-MM-DD')}.zip`,
          });
        } catch (error) {
          console.log(error);
        }
      }
      trackingClick({
        eventName: '下载图片',
        goodId: curInfo?.spu || '',
      });
    };

    const handleLink = (url: string) => {
      window.open(url);
    };

    // 重置灯箱图滚动器位置
    const initSwiper = (el: any) => {
      swiperEl.value = el;
    };

    // 直播信息滚动加载
    const pageInfo = ref({ pageNum: 1, pageSize: 10 });
    const isMore = ref<boolean>(true);
    const disabledLoad = computed(() => !isMore.value);
    const loadMoreLive = async () => {
      pageInfo.value.pageNum += 1;
      const param = {
        spu: info.value?.spu || '',
        ...pageInfo.value,
      };
      const { data } = await loadMoreDyLive(param);
      isMore.value = !!(data?.list && data?.list?.length);
      data?.list && info.value?.liveDetailPageData.list.push(...data?.list);
    };

    const handleSearch = () => {
      trackingClick({
        eventName: '找相似',
        goodId: info.value?.spu || '',
      });
      const newPage = $router.resolve({
        name: 'SearchImageList',
        query: {
          url: encodeURIComponent(info.value?.pictureList[currentIndex.value] || ''),
          channelId: CHANNEL_ENUM.DOUPIN,
        },
      });
      window.open(newPage.href);
    };

    // 选图弹窗-确定
    const handleSelectSuccess = (id: string) => {
      trackingClick({
        eventName: '选图弹窗-点击确定',
        goodId: selectImgConfig.value.data.spu,
        taskId: id,
      });
    };

    const init = () => {
      getInfo();
    };
    init();
    return {
      permissionConfig,
      info,
      designTaskOptions,
      selectImgConfig,
      handleSelectImgItem,
      handleDownload,
      handleLink,
      initSwiper,
      handleNext,
      handlePrev,
      handleItem,
      modules: [Navigation],
      currentIndex,
      loadMoreLive,
      disabledLoad,
      handleSearch,
      handleSelectSuccess,
    };
  },
  render() {
    return (
      <div>
        {this.info?.spu && (
          <main class="container">
            <header class="flex flex-justify-between">
              <div class="header-info_left">
                <span class="name">{this.info?.spuName}</span>
              </div>
              <div class="header-info_right flex flex-justify-between">
                <div class="cate">
                  品类：
                  {
                    [
                      this.info?.firstCname,
                      this.info?.secondCname,
                      this.info?.thirdCname,
                    ].filter(v => (v ?? '') !== '').join('/')
                  }
                </div>
                <div class="btns">
                  <div class="select choose-image" onClick={() => this.handleSelectImgItem(this.info)}>选图</div>
                  <el-tooltip content="添加到最近一次选图分组" placement="bottom-start">
                    <div
                      class="quick"
                      onClick={() => this.handleSelectImgItem(this.info, 'quick')}
                    >
                      <img src={IconSpeed}/>
                    </div>
                  </el-tooltip>
                </div>
                <div class="btns">
                  <div class="select download-btn"
                       onClick={() => this.handleDownload(this.info)}
                  >
                    下载图片
                  </div>
                </div>
              </div>
            </header>
            <main class="main-info">
              <section class="main-info_title flex flex-justify-start">
                <img src={IconStyle} class="icon"/>
                <div>商品概况</div>
              </section>
            </main>
            <section class="main">
              <div class="cover_layout">
                <custom-image
                  hide-on-click-modal={true}
                  src={this.info.pictureList[this.currentIndex]}
                  fit="cover"
                  class="main_cover"
                  preview-src-list={this.info.pictureList}
                  initial-index={this.currentIndex}
                />
                <div class="theme_layout">
                  <div class={{
                    btn: true,
                    prev: true,
                    disabled: this.currentIndex === 0,
                  }} onClick={this.handlePrev}>
                    <img src={IconLeftArrow} class="icon" />
                  </div>
                  <swiper
                    slides-per-view={4}
                    space-between={12}
                    slideToClickedSlide={true}
                    grabCursor={true}
                    centeredSlides={false}
                    onSwiper={this.initSwiper}
                  >
                    {this.info.pictureList.map((v:any, i:any) => (
                      <swiper-slide
                        key={i}
                        class="item"
                        v-slots={{
                          default: () => (
                            <div
                              class={{
                                cover: true,
                                active: this.currentIndex === i,
                              }}
                              onClick={() => this.handleItem(i)}
                            >
                              <img
                                src={v}
                                alt=""
                                class="img"
                              />
                            </div>
                          ),
                        }}
                      />
                    ))}
                  </swiper>
                  <div class={{
                    btn: true,
                    next: true,
                    disabled: this.currentIndex >= this.info.pictureList.length - 1,
                  }} onClick={this.handleNext}>
                    <img src={IconRightArrow} class="icon" />
                  </div>
                </div>
                {this.info.pictureList[this.currentIndex]
                && this.permissionConfig.SEARCH_IMAGE.value && <el-tooltip content="找相似" placement="bottom-end">
                  <div onClick={this.handleSearch} class="detail-search">
                    <i class="iconfont icon_zxs detail-search_icon" />
                  </div>
                </el-tooltip>}
              </div>
              <div class="desc-info">
                <el-descriptions title="基本信息" class-name="desc-info_item">
                  <el-descriptions-item label="店铺:">{this.info.storeName}</el-descriptions-item>
                  <el-descriptions-item label="SPU:">{this.info.spu}</el-descriptions-item>
                  <el-descriptions-item label="商品状态:">
                    {this.$filters.getEnumLabel(GOODS_STATE_LIST, this.info.commodityStatus)}
                  </el-descriptions-item>
                </el-descriptions>
                <el-descriptions title="销售情况" class-name="desc-info_item">
                  <el-descriptions-item label="价格:">{this.info.spuCouponPrice}</el-descriptions-item>
                  <el-descriptions-item label="总销量:">{this.info.spuTotalSales}</el-descriptions-item>
                  <el-descriptions-item label="总评论数:">{this.info.commentCnt}</el-descriptions-item>
                </el-descriptions>
                <el-descriptions title="商品属性" class-name="desc-info_item">
                  {Object.keys(this.info.productParametersObj).map((key) => {
                    return (
                      <el-descriptions-item label={`${key}:`}>
                        {this.info?.productParametersObj[key]}
                      </el-descriptions-item>
                    );
                  })}
                </el-descriptions>
                {/* <el-descriptions title="评论标签" class-name="desc-info_item">
                  <el-descriptions-item label="总评论数:">{`(${this.info.commentCnt || 0})`}</el-descriptions-item>
                </el-descriptions> */}
              </div>
            </section>
            {/* <main class="main-info">
              <section class="main-info_title flex flex-justify-start">
                <img src={IconStyle} class="icon"/>
                <div>带货记录</div>
              </section>
            </main>
            <section class="main infinite-table-wrapper">
              <el-table data={this.info?.liveDetailPageData?.list ?? []}
                        border
                        v-infinite-scroll={this.loadMoreLive}
                        infinite-scroll-disabled={this.disabledLoad}
              >
              <el-table-column prop="liveTitle" label="直播标题" />
              <el-table-column prop="anchorName" label="主播名称" />
              <el-table-column prop="audiencesCnt" label="本次累计观看人次" />
            </el-table>
            </section>
            <div class="align-center no-more-tip" v-show={this.disabledLoad}>No More</div> */}
            <select-img-dialog
              v-model={[this.selectImgConfig.visible, 'visible']}
              data={this.selectImgConfig.data}
              options={this.designTaskOptions}
              onSuccess={this.handleSelectSuccess}
            />
          </main>
        )}
      </div>
    );
  },
});
